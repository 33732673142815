import React, { FunctionComponent } from "react";
import Badge from "../Badge";
import "./styles.scss";

type BadgeListProps = {
  badges: string[];
};

const BadgeList: FunctionComponent<BadgeListProps> = ({ badges }) => {
  return (
    <div className="c-badge-list">
      {badges.map((badge: string, index: number) => {
        return <Badge key={index} badge={badge} />;
      })}
    </div>
  );
};

export default BadgeList;
