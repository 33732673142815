import React, { FunctionComponent } from "react";
import "./styles.scss";

type BadgeProps = {
  badge: string;
};

const Badge: FunctionComponent<BadgeProps> = ({ badge }) => {
  return <span className="c-badge">{badge}</span>;
};

export default Badge;
