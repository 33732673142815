import React, { FunctionComponent } from "react";
import Section from "../Section";
import RichText from "../RichText";
import "./styles.scss";
import { PortableText } from "../../types/SanityTypes";
import BadgeList from "../BadgeList";

type TeaserSeparatorProps = {
  theme?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  badges: string[];
  _rawTitle?: PortableText;
};

const TeaserSeparator: FunctionComponent<TeaserSeparatorProps> = ({ badges, theme, _rawTitle }) => {
  return (
    <Section sectionClassName="c-teaser-separator" theme={theme?.bgColor.title}>
      <div className="grid-row grid-row-vertical-center">
        <div className="grid-col-4 grid-col-md-3">
          <div className="c-teaser-separator--title">
            <RichText data={_rawTitle} />
          </div>
        </div>
        <div className="grid-col-8 grid-col-md-9">
          <BadgeList badges={badges} />
        </div>
      </div>
    </Section>
  );
};

export default TeaserSeparator;
